import React, { useState, useRef, useEffect } from 'react';
import './Chat.css';

import Header from '../components/header';
import Message from '../components/Message';

function Chat() {
  const [messages, setMessages] = useState([{
    ...{
      text: "Please enter an English sentence for the test.",
    }, type: 'start'
  }]);
  const [InputText, setInputText] = useState('');
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const endOfMessageRef = useRef(null);

  useEffect(() => {
    endOfMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendClick();
      inputRef.current.focus();
    }
  };

  const handleSendClick = async () => {
    if (!InputText || loading) return;
    if (InputText.length < 2) { setInputText(''); return; }
    let newMessage = {
      text: InputText
    };
    setInputText('');
    setLoading(true);
    setMessages(messages => messages.concat({ ...newMessage, type: 'sent' }));
    setMessages(messages => messages.concat({ text: '', type: 'received' }));
  };

  return (
    <div style={{ color: "#282c34" }}>
      <Header />
      <div className='chat-container'>
        <div className='message-container'>
          {messages.map((message, index) => (
            <span key={index}>
              <Message message={message} />
            </span>
          ))}
          <div ref={endOfMessageRef} />
        </div>
        <div className='input-area'>
          <input
            ref={inputRef}
            className='input'
            type="text"
            value={InputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={handleKeyPress}
            disabled={loading}
          />
          <button type="submit" onClick={handleSendClick} disabled={loading} className='button'>
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

export default Chat;
