import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './images/logo.png'

function Header() {
  return (
    <header className="py-3 border-bottom header">
      <div className="container d-flex flex-wrap justify-content-center">
        <a href="http://tutoruslabs.io" className="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto link-body-emphasis text-decoration-none">
          <img className="bi me-2" width="130" height="34.66" src={logo}></img>
          <div style={{ color: "white" }}>Tutoruslabs Grammar Correction Demo Site</div>
        </a>
      </div>
    </header>
  );
};

export default Header;