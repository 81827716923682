import React from "react";
import cat from "./images/cat.png"

function Message(props) {
  const email = "contact@tutoruslabs.com";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(email).then(() => {
      alert('Email address copied to clipboard!');
    }, () => {
      alert('Failed to copy email address.');
    });
  };

  const containerStyle = {
    padding: '20px',
    borderRadius: '5px',
    maxWidth: '400px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6'
  };

  const emailStyle = {
    display: 'inline-block',
    marginTop: '10px',
    cursor: 'pointer',
    color: '#1a73e8',
    textDecoration: 'none',
    fontWeight: 'bold'
  };

  let messageSellector = {
    sent: <div className={'message sent'}>{props.message.text}</div>,
    start: <div style={{ display: "flex" }}>
      <img height={"50px"} src={cat} style={{ margin: "10px", marginLeft: "-10px" }} />
      <div className={'message received'}>{props.message.text}</div>
    </div>,
    received:
      <div style={{ display: "flex" }}>
        <img height={"50px"} src={cat} style={{ margin: "10px", marginLeft: "-10px" }} />
        <div className={'message received'}>
          <div style={containerStyle}>
            <p>
              If you are interested in using the product,<br />
              please contact us at: <br />
              <span onClick={copyToClipboard} style={emailStyle}>
                {email}
              </span>
            </p>
          </div>
        </div>
      </div>
  };
  return (
    <div>{messageSellector[props.message.type]}</div>
  );
};

export default Message;